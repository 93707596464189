.App {
  text-align: center;
  padding: 15px 30px;
  height: 100%;


  font-size: medium;
  color: #3e3e3e;

  --primary-color: #e4f4fc;
  --medium-color: #4683a1;
  --dark-color: #404040;
}

.wrapper {
  max-width: 1200px;
  margin: auto;
}

ul {
  padding: 0;
  line-height: 2;
}

li {
  list-style: none;
  font-size: x-small;
  color: #5c5c5c;
}




button:disabled {
  border-color: #a4a4a4;
  color: #a4a4a4;
  cursor: default;

}

button:not(:disabled):hover {
  border-color: #e1eaf1;
}


a {
  text-decoration: none;
  color: inherit;
}


.main {
  height: 100%;
  align-items: flex-start !important;
}




.left {
  width: 480px;
  margin-top: 60px;
  margin-bottom: 30px;
}


.right {
  transform: scale(0.9);
  width: 480px;
}





@media screen and (max-width: 640px) {
  .App{
    font-size: small;

  }

  li {
    font-size: x-small;
  }
  .left {
    overflow-y: scroll;
    height: 450px;
    width: 100%;
    margin-bottom: 30px;
  }
  textarea {
    width: calc(100vw / 3);
    height: 30px;
  }
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: calc(100vw / 30);;
  }
  .right {
    transform: scale(0.5);
    margin-top: -210px;
    margin-bottom: -240px;
  }
}

.info {
  color: var(--medium-color);
  background-color: var(--primary-color);
  padding: 3px 6px;
  border-radius: 3px;
  font-size: x-small;
}








/* tooltip */


[role="tooltip"],
.hidetooltip.hidetooltip.hidetooltip + [role="tooltip"] {
  visibility: hidden;
  position: relative;
  top: -45px;
  left: 2rem;
  background: var(--primary-color);
  color: var(--dark-color);
  padding: 3px;
}
[aria-describedby]:hover,
[aria-describedby]:focus {
  position: relative;
}
[aria-describedby]:hover + [role="tooltip"],
[aria-describedby]:focus + [role="tooltip"] {
  visibility: visible;
}